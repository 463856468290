import React from "react"
import Contacts from "../../components/contacts"
import styled from "styled-components"
import Seo from "../../components/seo"
import Quote from "../../components/about-page/quote"
import Headquarters from "../../components/about-page/headquarters"
import Interested from "../../components/about-page/interested"
import ContactUs from "../../components/about-page/contact-us"
import HeaderLp from "../../components/landing-pages/headerLp"

const Style = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
`

function About({ location }) {
  return (
    <>
      <Seo title="About us" noindex={true}></Seo>
      <Style>
        <HeaderLp wrapLocation={location} />
        <Quote />
        <Headquarters />
        <Interested />
        <ContactUs />
      </Style>
    </>
  )
}

export default About
